<script lang="ts" setup>
import {
  convertPersianToGregorian,
  getCurrentPersianDate,
  getPersianDaysInMonth,
  getPersianMonths,
  getPersianWeekdays, parsePersianDate,
} from '~/utils/calendar/persian-utils';
import {
  convertGregorianToPersian, formatGregorianDate,
  getCurrentGregorianDate,
  getGregorianDaysInMonth,
  getGregorianMonths,
  getGregorianWeekdays, parseGregorianDate,
} from '~/utils/calendar/gregorian-utils';
import type {FlightSearchDate} from "~/types/flight/search/FlightSearchDate";

const emit = defineEmits(['handleDate'])
// Props
const props = defineProps({
  calendarType: {
    type: String,
    default: 'persian', // 'persian' or 'gregorian'
  },
  dates: {
    type: Object as () => FlightSearchDate,
    default: {
      start_date: {
        persian: '',
        gregorian: ''
      },
      end_date: {
        persian: '',
        gregorian: ''
      }
    }
  },
  monthCount: {
    type: Number,
    default: 2, // Default to 2 months
  },
  selectionType: {
    type: String,
    default: 'single', // 'single' or 'range'
  },
  disableExpiredDates: {
    type: Boolean,
    default: true, // Disable expired dates by default
  },
  expirationDate: {
    type: String,
    default: null, // Optional expiration date
  },
  color: {
    type: String,
    default: 'primary'
  },
  sortPrices: {
    type: Array as () => { date: string; price: number }[],
    default: () => [],
  },
});

// State

const calendarType = ref(props.calendarType);
const currentYear = ref(0);
const currentMonth = ref(0);
const sentinel = ref<HTMLElement | null>(null);
const displayedMonths = ref<{ year: number; month: number }[]>([]);
const {isDesktop} = useDevice()

const priceMap = computed(() => {
  return new Map(props.sortPrices.map(item => [item.date, item.price]));
});

// Compute the lowest price for each week
const weeklyLowestPrice = computed(() => {
  const lowestPrices: Record<string, number> = {};

  if (!props.sortPrices.length) return lowestPrices; // Return empty if no prices

  props.sortPrices.forEach(({date, price}) => {
    const weekNumber = getWeekNumber(date); // Function to get week number
    if (!lowestPrices[weekNumber] || price < lowestPrices[weekNumber]) {
      lowestPrices[weekNumber] = price;
    }
  });

  return lowestPrices;
});

// Function to get the week number from a date
const getWeekNumber = (dateStr: string) => {
  const date = new Date(dateStr);
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / (1000 * 60 * 60 * 24);
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const colorClasses = computed(() => props.color);
const initializeDate = () => {
  if (calendarType.value === 'persian') {
    const {year, month} = parsePersianDate(getCurrentPersianDate());
    currentYear.value = year;
    currentMonth.value = month;
  } else {
    const {year, month} = parseGregorianDate(getCurrentGregorianDate());
    currentYear.value = year;
    currentMonth.value = month;
  }

  if (!isDesktop || !displayedMonths.value.length)
    loadMoreMonths();
};


onMounted(() => {
  initializeDate();

  if (!isDesktop) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadMoreMonths();
          forceLayoutUpdate();
        }
      });
    }, {
      root: null,
      rootMargin: '200px',
      threshold: 0.5
    });
    if (sentinel.value) {
      observer.observe(sentinel.value);
    }
  }
});
watch(() => calendarType.value, () => {
  displayedMonths.value = []
  initializeDate();
});
const selectedStartDate = ref<string | null>(calendarType.value === 'persian' ? props.dates.start_date.persian : props.dates.start_date.gregorian);
const selectedEndDate = ref<string | null>(calendarType.value === 'persian' ? props.dates.end_date.persian : props.dates.end_date.gregorian);

// Displayed Months
const loadMoreMonths = () => {
  const monthsToAdd = [];
  let year = displayedMonths.value.length
      ? displayedMonths.value[displayedMonths.value.length - 1].year
      : currentYear.value;
  let month = displayedMonths.value.length
      ? displayedMonths.value[displayedMonths.value.length - 1].month + 1
      : currentMonth.value;

  for (let i = 0; i < props.monthCount; i++) {
    if (month > 11) {
      month = 0;
      year++;
    }
    monthsToAdd.push({year, month});
    month++;
  }
  displayedMonths.value.push(...monthsToAdd);
};

const forceLayoutUpdate = () => {
  displayedMonths.value = [...displayedMonths.value];
};
// Calendar Details
const months = computed(() =>
    calendarType.value === 'persian' ? getPersianMonths() : getGregorianMonths()
);

const weekdays = computed(() =>
    calendarType.value === 'persian' ? getPersianWeekdays() : getGregorianWeekdays()
);

const getDaysInMonth = (year: number, month: number) => {
  return calendarType.value === 'persian'
      ? getPersianDaysInMonth(
          year,
          month,
          selectedStartDate.value,
          selectedEndDate.value,
          props.expirationDate,
          props.disableExpiredDates
      )
      : getGregorianDaysInMonth(
          year,
          month,
          selectedStartDate.value,
          selectedEndDate.value,
          props.expirationDate,
          props.disableExpiredDates
      );
};

// Methods
const selectDate = (date: string | null) => {
  if (!date) return;

  if (props.selectionType === 'single') {
    selectedStartDate.value = date;
    selectedEndDate.value = null;
  } else {
    if (!selectedStartDate.value || (selectedStartDate.value && selectedEndDate.value)) {
      selectedStartDate.value = date;
      selectedEndDate.value = null;
    } else if (!selectedEndDate.value) {
      selectedEndDate.value = date;

      // Swap dates if needed
      if (selectedStartDate.value > selectedEndDate.value) {
        [selectedStartDate.value, selectedEndDate.value] = [selectedEndDate.value, selectedStartDate.value];
      }
    }
  }
};

const toggleCalendarType = () => {
  calendarType.value = calendarType.value === 'persian' ? 'gregorian' : 'persian';
};

const prevMonth = () => {
  // Move one month back
  if (displayedMonths.value.length > 0) {
    let {year, month} = displayedMonths.value[0]; // Get the first displayed month
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }
    // Add the previous month at the beginning of the array
    displayedMonths.value.unshift({year, month});
    // Remove the last month if more than required
    if (displayedMonths.value.length > props.monthCount) {
      displayedMonths.value.pop();
    }
  }
};

const nextMonth = () => {
  // Move one month forward
  if (displayedMonths.value.length > 0) {
    let {year, month} = displayedMonths.value[displayedMonths.value.length - 1]; // Get the last displayed month
    if (month === 11) {
      month = 0;
      year++;
    } else {
      month++;
    }
    // Add the next month at the end of the array
    displayedMonths.value.push({year, month});
    // Remove the first month if more than required
    if (displayedMonths.value.length > props.monthCount) {
      displayedMonths.value.shift();
    }
  }
};

const isPrevMonthExpired = computed(() => {
  if (!props.disableExpiredDates) return false;

  // Use today as expiration date if not provided
  const today = calendarType.value === 'persian'
      ? parsePersianDate(getCurrentPersianDate())
      : parseGregorianDate(getCurrentGregorianDate());

  const expiration = props.expirationDate
      ? (calendarType.value === 'persian'
          ? parsePersianDate(props.expirationDate)
          : parseGregorianDate(props.expirationDate))
      : today;

  // Ensure displayedMonths has at least one month to evaluate
  if (!displayedMonths.value.length) {
    return false; // Safe default, allow navigation until loaded
  }

  const firstMonth = displayedMonths.value[0]; // Get the first displayed month
  const prevMonth = firstMonth.month === 0
      ? {year: firstMonth.year - 1, month: 11}
      : {year: firstMonth.year, month: firstMonth.month - 1};

  // Check if the previous month is before the expiration date
  return (
      prevMonth.year < expiration.year ||
      (prevMonth.year === expiration.year && prevMonth.month < expiration.month)
  );
});


const clearSelection = () => {
  selectedStartDate.value = null;
  selectedEndDate.value = null;
};
const confirmSelection = () => {

  const result = finalResult()

  emit('handleDate', result, true)
};

const finalResult = () => {
  return {
    start_date: selectedStartDate.value
        ? {
          persian: calendarType.value === 'persian'
              ? selectedStartDate.value // Already in Persian format
              : convertGregorianToPersian(selectedStartDate.value), // Convert Gregorian to Persian
          gregorian: calendarType.value === 'gregorian'
              ? selectedStartDate.value // Already in Gregorian format
              : convertPersianToGregorian(selectedStartDate.value), // Convert Persian to Gregorian
        }
        : {
          persian: '',
          gregorian: '',
        },
    end_date: selectedEndDate.value
        ? {
          persian: calendarType.value === 'persian'
              ? selectedEndDate.value // Already in Persian format
              : convertGregorianToPersian(selectedEndDate.value), // Convert Gregorian to Persian
          gregorian: calendarType.value === 'gregorian'
              ? selectedEndDate.value // Already in Gregorian format
              : convertPersianToGregorian(selectedEndDate.value), // Convert Persian to Gregorian
        }
        : {
          persian: '',
          gregorian: '',
        },
  };
}

watch(() => selectedStartDate.value, () => emit('handleDate', finalResult(), false), {deep: true, immediate: true});
watch(() => selectedEndDate.value, () => emit('handleDate', finalResult(), false), {deep: true, immediate: true});
</script>

<template>
  <div class="w-full bg-white rounded-lg border shadow relative">

    <div class="sticky top-0 z-10 bg-white md:relative flex justify-between items-center p-1.5 border-b">
      <button
          @click="prevMonth"
          class="hidden md:block"
          :class="`text-${colorClasses}-500 hover:text-${colorClasses}-700`"
          :disabled="isPrevMonthExpired"
      >→
      </button>
      <div class="font-semibold text-sm">
        {{ calendarType === 'persian' ? 'تقویم شمسی' : 'Gregorian Calendar' }}
      </div>
      <button
          @click="nextMonth"
          class="hidden md:block"
          :class="`text-${colorClasses}-500 hover:text-${colorClasses}-700`"
      >←
      </button>
    </div>
    <div class="w-full flex flex-col relative mb-20 md:mb-0 h-auto md:min-h-[320px]">
      <div class="sticky top-[3.8rem] md:hidden grid grid-cols-7 text-center text-xs text-gray-500 z-10 bg-gray-100">
        <div v-for="day in weekdays" :key="day" class="py-2">{{ day }}</div>
      </div>

      <div
          class="grid grid-cols-1 md:grid-cols-[repeat(auto-fit,_minmax(250px,_1fr))] gap-4 max-h-screen overflow-y-scroll">
        <div v-for="(month, index) in displayedMonths" :key="index" class="p-2 w-full border-gray-200 relative">
          <div class="sticky top-0 p-2 z-10 bg-white md:hidden text-center font-bold mb-4 text-lg">
            {{ months[month.month] }} {{ month.year }}
          </div>
          <div
              v-if="index !== displayedMonths.length - 1"
              class="hidden md:block absolute right-0 top-0 h-full border-r border-gray-200"
          ></div>
          <div
              v-if="index !== displayedMonths.length - 1"
              class="block md:hidden absolute bottom-0 left-0 w-full border-b border-gray-200"
          ></div>

          <div class="hidden md:block text-center font-bold mb-2 text-sm">
            {{ months[month.month] }} {{ month.year }}
          </div>

          <div class="hidden md:grid grid-cols-7 text-center text-xs text-gray-500 mb-2">
            <div v-for="day in weekdays" :key="day">{{ day }}</div>
          </div>
          <div class="grid grid-cols-7 gap-y-0.5">
            <div
                v-for="(day, i) in getDaysInMonth(month.year, month.month)"
                :key="i"
                class="flex flex-col justify-center items-center h-10 text-xs cursor-pointer relative"
                :class="{
    [`bg-${colorClasses}-500 text-white`]: day.selected,
    [`bg-${colorClasses}-50 text-${colorClasses}-700`]: day.inRange,
    'rounded-r-md': day.selected && day.isStart && !day.isEnd && selectionType === 'range',
    'rounded-l-md': day.selected && day.isEnd && !day.isStart,
    'rounded-md': day.selected && !day.isStart && !day.isEnd || selectionType === 'single',
    'text-gray-400 cursor-not-allowed custom-expired-line': day.isExpired,
    [`hover:bg-${colorClasses}-50`]: day.date && !day.selected && !day.inRange && !day.isExpired,
  }"
                :disabled="day.isExpired"
                @click="!day.isExpired && selectDate(day.date)"
            >
              <span>{{ day.label }}</span>

              <span v-if="priceMap.get(calendarType === 'persian' ? convertPersianToGregorian(day.date) : day.date)"
                    class="text-xs text-black/40"
                    :class="{
                'text-green-500': priceMap.get(calendarType === 'persian' ? convertPersianToGregorian(day.date) : day.date) === weeklyLowestPrice[getWeekNumber(calendarType === 'persian' ? convertPersianToGregorian(day.date) : day.date)],
                'text-white': day.selected,
                [`text-${colorClasses}-700`]: day.inRange
              }">{{ usePriceFormat(priceMap.get(calendarType === 'persian' ? convertPersianToGregorian(day.date) : day.date) / 1000) }}
              </span>
              <span v-else-if="day.label && sortPrices.length && !day.isExpired"
                    class="text-xs text-red-500"
                    >-</span>
            </div>

          </div>
        </div>
      </div>
      <div ref="sentinel"></div>
    </div>
    <div class="fixed bottom-0 inset-x-0 z-10 bg-white md:relative flex justify-between items-center p-2 border-t">
      <button @click="toggleCalendarType" class="text-blue-500 hover:underline">
        تغییر به {{ calendarType === 'persian' ? 'میلادی' : 'شمسی' }}
      </button>
      <div class="flex gap-4">
        <button
            @click="clearSelection"
            class=" hover:underline mr-4"
            :class="`text-${colorClasses}-500`"
        >حذف
        </button>
        <button
            @click="confirmSelection"
            class="text-white px-4 py-2 rounded"
            :class="`bg-${colorClasses}-500 hover:bg-${colorClasses}-600 disabled:bg-${colorClasses}-50`"
            :disabled="!selectedStartDate || (selectionType === 'range' && !selectedEndDate)"
        >
          تایید
        </button>
      </div>
    </div>
  </div>
</template>


<style>
/* Custom class for oblique line on expired dates */
.custom-expired-line::before {
  content: '';
  position: absolute;
  width: 20%;
  height: 1px;
  background-color: #bdbdbd;
  transform: rotate(-45deg) translate(-50%, 0);
  top: 38%;
  left: 50%;
  pointer-events: none;
}

</style>
